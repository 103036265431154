<template lang="pug">
  div(class="container member bg-white" style="min-height: calc(100vh - 190px);")
    div(class="px-sm-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      .container.mb-5
        .row.justify-content-center
          .col-md-auto.c-width
            .h4.mb-4.font-weight-bold.text-center 業績查詢
            .c-BR.shadow.text-left(:style="{backgroundImage: `url(${bgImage})`}")
              h5.card-title.pv-red.text-center 注意事項
              li.pv-red-atten 個人當月消費業績未滿 4,000 積分，不能領取各項獎金。
              li.pv-red-atten 堂主當月合格小組業績未達 60,000 積分以上，不能領取領導獎金。
              li.mt-3 以下業績僅供參考，實際業績以公司電腦運算結果為準
              li 如有相關問題，請於上班時間電洽東震股份有限公司
              li 服務專線：04-22581569 轉財務部
                span.px-3 感謝您的查詢。

      .aligner
        .circleName.shadow.font-bold {{ UserData.hierarchical }}
        .eyeopenhide
          img(src="@/assets/images/eyeopen.svg" v-if="eyeopen" @click="eyeClick()")
          img(src="@/assets/images/eyeclose.svg" v-else @click="eyeClick()")
      .row.justify-content-center.mt-3
        .row.row-cols-2.row-cols-md-2.g-4
          .col.mb-4
            .card.h-100.shadow.pvcardbody.float-right
              .card-body.p-2.m-2
                h5.card-title.pvc-title 當月
                .content
                  div(class="content__item")
                    .value {{ eyeopen ? commaFormat(UserData.pv.pv) : '＊' }}
                    .title 個人業績
                  div(class="content__item")
                    .value {{ eyeopen ? UserData.hierarchical === '傳銷商' ? commaFormat(UserData.pv.team_pv) : commaFormat(UserData.pv.master_team_pv) : '＊' }}
                    .title {{ UserData.hierarchical === '傳銷商' ?'整組業績':  '堂主小組業績'  }}
          .col.mb-4
            .card.h-100.shadow.pvcardbody.float-left
              .card-body.p-2.m-2
                h5.card-title.pvc-title 上月
                .content
                  div(class="content__item")
                    .value {{ eyeopen ? commaFormat(UserData.pv.pre_pv) : '＊' }}
                    .title 個人業績
                  div(class="content__item")
                    .value {{ eyeopen ? UserData.hierarchical === '傳銷商' ? commaFormat(UserData.pv.pre_team_pv) : commaFormat(UserData.pv.pre_master_team_pv) : '＊' }}
                    .title {{ UserData.hierarchical === '傳銷商' ?'整組業績':  '堂主小組業績'  }}
        .container.mb-4
          .row.justify-content-center
            .col-md-auto.c-width
              .c-BR.shadow.text-center(v-if="UserData.hierarchical !== '督導' && UserData.hierarchical !== '堂主'")
                li.pvtxt-18.font-weight-bold
                  | {{ UserData.hierarchical === '傳銷商' ? '至上月累積整組業績' : '至上月累積積分' }}
                  span.greenTxtvalue  {{ eyeopen ? commaFormat(UserData.pv.accumulated_team_pv) : '＊' }}
        .row.justify-content-center
          .row.row-cols-2.row-cols-md-2.g-4
            .col.mb-2
              .card.h-100.shadow.pvcardbody.float-right
                .card-body.p-1.m-1.mt-3.mb-3
                  .content
                    div(class="content__item", style="width:60%")
                      div
                      img(src="@/assets/images/airplan.png")
                      .title 旅遊點數
                    div(class="content__item", style="align-items: center;")
                      .value {{ eyeopen ? commaFormat(UserData.pv.tour_pv) || 0 : '＊' }}
                      .title {{ UserData.pv.tour_yyyymm ? `截止 ${UserData.pv.tour_yyyymm} 為止` : '無點數紀錄' }}
        .container.mb-5.mt-4(v-if="showFirst || showlastMonth || showExit")
          .row.justify-content-center
            .col-md-auto.c-width
              .c-BR.shadow.text-center(style="background-color: rgb(238, 238, 238);")
                h5.card-title 年度積分
                div(v-if="showExit")
                  div.pvtxt-16(class="px-5 py-4") <b>您的傳銷權資格已退出，</b><br/>需先辦理重新入會，如有任何疑問，請致電：04-22581569。
                div(v-if="showFirst")
                  div.pvtxt-16(v-if="expiryDateOnly < nowDateOnly && yearPv < 6000" class="px-5 py-4") 提醒您！您的有效期限至 <span style = "color: red;">{{expiry_day}}</span> ，目前無法購物，<br/>請於 <span style = "color: red;">{{renewal_expiry_date}}</span> 前至各營業所辦理續約，以維護個人權益。
                  div.pvtxt-16(v-else class="px-5 py-4") 您的傳銷權有效期限至今年底，無需辦理續約。
                div(v-else-if="showlastMonth")
                  div.pvtxt-16(v-if="yearPv < 6000" class="px-5 py-4") 您今年個人消費累計為 <span style = "color: red;">{{parseInt(yearPv).toLocaleString() }} PV</span> <p class="mt-2" style="font-size:20px;">若是您在 <span style = "color: red;">12月31日</span> 前補足 <span style="color: red">6,000 PV</span> ，屆時公司將為您的有效期限展延至明年底</p>
                  div.pvtxt-16(v-else class="px-5 py-4") 您今年個人消費累計已大於 <span style="color: red">6,000 PV</span> ，傳銷商資格將自動展延至明年底。
</template>

<script>
import { mapState } from 'vuex'
import bgImage from '@/assets/images/bg54271df7.jpg'

export default {
  name: 'Favorites',
  data () {
    return {
      bgImage,
      eyeopen: false,
      isShow: false,
      showFirst: false,
      showMiddleMonth: false,
      showlastMonth: false,
      showExit: false,
      yearPv: '',
      renewal_expiry_date: '',
      renewal_month: 0,
      expiry_day: '',
      expiry_year: 2025,
      renewal_expiry_date_time: 0
    }
  },
  computed: {
    ...mapState({
      UserData: state => state.model.UserData
    })
  },
  created () {
    setTimeout(() => {
      // const yearPvAlert = window.localStorage.getItem('yearPvAlert')
      const nowDate = new Date()
      const currMonth = nowDate.getMonth() + 1
      const renewalExpiryDate = new Date(this.UserData.renewal_expiry_date)
      const expiryDate = new Date(this.UserData.expiry_day)
      const created = new Date(this.UserData.created_at)
      console.log('this.UserData')
      console.log(JSON.stringify(this.UserData))
      console.log('currMonth', currMonth)
      console.log('renewalExpiryDate', renewalExpiryDate)

      // pv
      if (this.UserData.pv === null || this.UserData.pv.year_pv === null || this.UserData === null) {
        this.yearPv = 0
      } else {
        this.yearPv = Number(this.UserData.pv.year_pv)
      }

      // 續約截止日
      if (this.UserData.renewal_expiry_date === null) {
        this.renewal_expiry_date = 0
      } else {
        // console.log('this.UserData.renewal_expiry_date', this.UserData.renewal_expiry_date)
        this.renewal_month = renewalExpiryDate.getMonth() + 1
        this.renewal_expiry_date = renewalExpiryDate.getFullYear() + '/' + this.renewal_month + '/' + renewalExpiryDate.getDate()
        this.renewal_expiry_date_time = renewalExpiryDate.getTime()
      }

      // 到期日
      if (this.UserData.expiry_day === null) {
        this.expiry_day = 0
      } else {
        this.expiry_day = expiryDate.getFullYear() + '/' + (expiryDate.getMonth() + 1) + '/' + expiryDate.getDate()
        this.expiry_year = expiryDate.getFullYear()
      }

      this.expiryDateOnly = new Date(expiryDate).setHours(0, 0, 0, 0)
      this.nowDateOnly = new Date(nowDate).setHours(0, 0, 0, 0)

      console.log(created.getFullYear())
      console.log(nowDate.getFullYear())

      // 退出
      if (this.UserData.exited_at != null && this.UserData.exited_at !== '') {
        this.showExit = true
        console.log('showExit = true')
      } else {
        // 還沒退出
        if (currMonth === 9 || currMonth === 10 || currMonth === 11 || currMonth === 12) { // 9~12
          this.showlastMonth = true
        } else if (created.getFullYear() === nowDate.getFullYear()) { // 今年加入
          this.showFirst = false
          this.showlastMonth = false
          console.log('not show showlastMonth = false showMiddleMonth = false ')
        } else if (currMonth >= 1 && currMonth < 9) { // 1~8
          this.showFirst = true
          console.log('showlastMonth = true ')
        }
      }
    }, 1500)
  },
  methods: {
    commaFormat (value) {
      return value && value
        .toString()
        .replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, (all, pre, groupOf3Digital) => {
          return pre + groupOf3Digital.replace(/\d{3}/g, ',$&')
        })
    },
    eyeClick () {
      this.eyeopen = !this.eyeopen
    }
  }
}
</script>

<style lang="scss" scoped>
.pvc-title {
  font-size: 16px;
  text-align: center;
}
.pvtxt-16 {
  font-size: 16px;
}
.pvtxt-18 {
  font-size: 18px;
}

.c-BR {
  border-radius: 10px;
  padding: 30px 15px;
  border: #e4e4e4 1px solid;
  margin: 0px 10px;
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .c-BR {
    padding: 15px 15px;
    width: 30rem;
  }
}
@media only screen and (max-width: 575px) {
  .c-BR {
    width: 495px;
  }
}
@media only screen and (min-width: 471px) and (max-width: 576px) {
  .c-BR {
    padding: 15px 15px;
    width: 30rem;
  }
}
@media only screen and (max-width: 470px) {
  .c-BR {
    padding: 15px 15px;
    width: 25rem;
  }
}
@media only screen and (max-width: 390px) {
  .c-BR {
    padding: 15px 15px;
    width: 23rem;
  }
}
.c-width {
  width: 41rem;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .c-width {
    width: 39rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .c-width {
    width: 36rem;
  }
}

@media only screen and (max-width: 575px) {
  .c-width {
    width: 33rem;
  }
}
@media only screen and (min-width: 471px) and (max-width: 576px) {
  .c-width {
    width: 33rem;
  }
}
@media only screen and (max-width: 470px) {
  .c-width {
    width: 33rem;
  }
}
@media only screen and (max-width: 390px) {
  .c-width {
    width: 38rem;
  }
}

.pv-red {
  color: brown;
}

.pv-red-atten {
  color: rgb(212, 0, 0);
}

li {
  list-style: none;
  margin: 0px;
}

div {
  box-sizing: border-box;
}

.cpvbox {
  width: 18rem;
  border-radius: 10px;
  border: #e9e9e9 1px solid;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cpvbox {
    width: 18rem;
  }
}

@media only screen and (max-width: 767px) {
  .cpvbox {
    width: 13rem;
  }
}

@media only screen and (max-width: 575px) {
  .cpvbox {
    width: 13rem;
  }
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  position: relative;
}

.circleName {
  font-size: 18px;
  font-weight: bold;
  height: 150px;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 6px 1px #e4e4e4;
  display: flex;
  height: 100px;
  justify-content: center;
  margin: 15px auto -30px;
  position: absolute;
  width: 100px;
  z-index: 2;
  top: -30px;
}

.eyeopenhide {
  position: relative;
  width: 24px;
  top: -10px;
  z-index: 3;
  left: 80px;
}

.content {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}

.content__item {
  text-align: center;
  width: 100%;
}
.content__item:first-child {
  border-right: 1px solid #cacaca;
  padding-right: 5px;
  padding-left: 5px;
}
.content__item .value {
  color: #00802f;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 5px;
}
@media only screen and (max-width: 575px) {
  .content__item .value {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 470px) {
  .content__item .value {
    font-size: 1rem;
  }
}
.content__item .title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .content__item .title {
    font-size: 0.95rem;
  }
}
@media only screen and (max-width: 575px) {
  .content__item .title {
    font-size: 0.8rem;
  }
  .eyeopenhide {
    left: 80px;
  }
}
@media only screen and (max-width: 470px) {
  .content__item .title {
    font-size: 0.72rem;
  }
}
.pvcardbody {
  width: 18rem;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pvcardbody {
    width: 17rem;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pvcardbody {
    width: 14.5rem;
  }
}
@media only screen and (max-width: 575px) {
  .pvcardbody {
    width: 15rem;
  }
}
@media only screen and (min-width: 471px) and (max-width: 576px) {
  .pvcardbody {
    width: 14rem;
  }
}
@media only screen and (max-width: 470px) {
  .pvcardbody {
    width: 11.5rem;
  }
}
@media only screen and (max-width: 390px) {
  .pvcardbody {
    width: 10.5rem;
  }
}

.greenTxtvalue {
  color: #00802f;
}
.pv_uBoxp {
  padding-left: 20px;
  padding-right: 20px;
}

</style>
