import DataModel from '../../library/proto/data-model'
import OfficesModel from './offices'
import { getUserLogin, getUser, getRenewalSettings } from '../../api'
import { tokenFormat } from '../../library'

/**
 * @param {Number} manage_id 管理編號
 * @param {String} hierarchical 職稱
 * @param {Number} pv 個人消費
 * @param {Number} team_pv 個人組織業績
 * @param {Number} master_team_pv 堂主小組業績
 * @param {Number} accumulated_team_pv 累積整組業績
 * @param {Number} pre_pv 上月個人消費
 * @param {Number} pre_team_pv 上月個人組織業績
 * @param {Number} pre_master_team_pv 上月堂主小組業績
 * @param {Number} pre_accumulated_team_pv 上月累積整組業績
 * @param {Number} year_pv 累積積分
 * @param {Number} tour_pv 本期累計點數
 * @param {Number} pre_tour_pv 前期旅遊點數
 */
class PV {
  constructor (args) {
    const entity = args || {}
    this.accumulated_team_pv = entity.accumulated_team_pv || 0
    this.cost_pv = entity.cost_pv || 0
    this.master_team_pv = entity.master_team_pv || 0
    this.pre_accumulated_team_pv = entity.pre_accumulated_team_pv || 0
    this.pre_master_team_pv = entity.pre_master_team_pv || 0
    this.pre_pv = entity.pre_pv || 0
    this.pre_team_pv = entity.pre_team_pv || 0
    this.pre_tour_pv = entity.pre_tour_pv || 0
    this.team_pv = entity.team_pv || 0
    this.tour_pv = entity.tour_pv || 0
    this.year_pv = entity.year_pv || 0
  }
}

/**
 * @param {Number} id 該Model的辨識索引
 * @param {String} name 使用者的名字
 * @param {String} web_name 使用者顯示在頁面的名字
 * @param {String} birthday 使用者的生日
 * @param {String} email 使用者的電子郵件
 * @param {String} email_verified_at
 * @param {String} character_auth 使用者是否有觀看命盤八字的權限
 * @param {String} company_owner
 * @param {String} hierarchical
 * @param {String} home_phone 使用者的室內電話
 * @param {String} identity_card 使用者的身分證/統一編號
 * @param {String} manage_id
 * @param {String} residence_address 使用者的地址
 * @param {String} mobile_phone01 使用者的行動電話，收OTP使用
 * @param {String} mobile_phone02 使用者的行動電話
 * @param {String} spouse_name
 * @param {String} spouse_identity_card
 * @param {String} sales_territory_id 使用者所屬的業績地區
 * @param {OfficesModel} sales_territory 頁計地區的關聯資料
 * @param {String} barcode01 使用者有登陸的手機條碼
 * @param {String} barcode02
 * @param {String} barcode03
 * @param {String} exited_at
 * @param {String} expiry_day
 * @param {String} credit_audit
 * @param {String} updated_user 最後修改的管理者
 * @param {String} update_time
*/
export default class UserModel extends DataModel {
  constructor (args) {
    super(args)
    const entity = args || {}
    this.id = entity.id || 0
    this.name = entity.name || ''
    this.web_name = entity.web_name || ''
    this.birthday = entity.birthday || ''
    this.email = entity.email || ''
    this.email_verified_at = entity.email_verified_at || ''
    this.character_auth = entity.character_auth || ''
    this.company_owner = entity.company_owner || ''
    this.hierarchical = entity.hierarchical || ''
    this.home_phone = entity.home_phone || ''
    this.identity_card = entity.identity_card || ''
    this.manage_id = entity.manage_id || ''
    this.mailing_address = entity.mailing_address || ''
    this.residence_address = entity.residence_address || ''
    this.mobile_phone01 = entity.mobile_phone01 || ''
    this.mobile_phone02 = entity.mobile_phone02 || ''
    this.spouse_name = entity.spouse_name || ''
    this.spouse_identity_card = entity.spouse_identity_card || ''
    this.sales_territory_id = entity.sales_territory_id || null
    this.sales_territory = new OfficesModel(entity.sales_territory)
    this.pv = new PV(entity.pv)
    this.barcode01 = entity.barcode01 || ''
    this.barcode02 = entity.barcode02 || ''
    this.barcode03 = entity.barcode03 || ''
    this.exited_at = entity.exited_at || ''
    this.expiry_day = entity.expiry_day || ''
    this.credit_audit = entity.credit_audit || ''
    this.updated_user = entity.updated_user || ''
    this.update_time = entity.update_time || ''
    // 特定頁面使用
    this.loginStatus = !!(localStorage.getItem('token'))
    this.username = ''
    this.password = ''
    this.password_reset = ''
    this.password_check = ''
    this.old_password = ''
    this.new_password = ''
    this.confirm_password = ''
    this.regulation = []
    this.otp = ''
    this.error_message = ''
    this.confirm_email = ''
    this.renewal_expiry_date = ''
  }

  /**
   * 登入時檢查是否有 token ，如果有直接更新 UserData，若無則取 token 並更新 UserData、localStorage("token")。
   */
  login () {
    return new Promise((resolve, reject) => {
      // console.log('localStorage', localStorage.getItem('token'))
      if (JSON.parse(localStorage.getItem('token'))) {
        getUser().then(res => {
          const user = res.data
          this.set(user)
          getRenewalSettings().then(response => {
            const data = response.data
            this.renewal_expiry_date = data[0].renewal_expiry_date
            resolve(res)
          }).catch(err => reject(err))
          resolve(res)
        }).catch(err => reject(err))
        return
      }
      getUserLogin({
        username: this.username,
        password: this.password
      }).then(res => {
        const token = res.data.token
        this.loginStatus = true
        localStorage.setItem('token', JSON.stringify(
          tokenFormat(token)
        ))
        getUser().then(response => {
          const user = response.data
          // console.log(user)
          this.set(user)
          getRenewalSettings().then(response => {
            const data = response.data
            this.renewal_expiry_date = data[0].renewal_expiry_date
            resolve(res)
          }).catch(err => reject(err))
        }).catch(err => reject(err))
      }).catch(err => reject(err))
    })
  }

  /**
   * 當發生登入失敗時要處理的行為
   * @param {*} err.response.data..message 對應的錯誤訊息
   * @param {*} err.response.data.status_from_backend 對應的錯誤編碼
   */
  loginError (err) {
    localStorage.clear()
    this.set(new UserModel())
    let remindFail = '登入失敗'
    if (err.response && err.response.data) {
      const statusFromBackend = Number(err.response.data.status_from_backend)
      if (statusFromBackend === 4001) {
        remindFail = '該用戶有效日期已過，請聯繫東震客服'
      } else if (statusFromBackend === 4002) {
        remindFail = '非首次登入'
      } else if (statusFromBackend === 4003) {
        remindFail = '尚未完成開通，請至第一次登入'
      } else if (statusFromBackend === 4004) {
        remindFail = '帳號或密碼錯誤，請重新輸入'
      } else if (statusFromBackend === 4005) {
        remindFail = '你的傳銷權資格已退出，需先辦理重新入會，如有任何疑問，請致電04-22581569'
      } else if (statusFromBackend === 40041) {
        remindFail = '操作過於頻繁，請於一分鐘後再試'
      } else if (statusFromBackend === 40042) {
        remindFail = '舊密碼輸入錯誤，請重新輸入'
      } else if (err.response.status === 404) {
        remindFail = '查無此帳號，請重新輸入'
      }
      this.validate({
        error_message: { inclusion: { message: `^${remindFail}` } },
        username: { inclusion: { message: '^' } },
        password: { inclusion: { message: '^' } }
      })
    }
  }

  rules () {}
}
