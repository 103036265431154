<template lang="pug">
  div(class="container member bg-white" style="min-height: calc(100vh - 190px);")
    div(class="px-sm-3 px-md-4 px-xl-5 pb-5 pt-md-4 pt-xl-4")
      h3(class="mb-4 font-weight-bold") 命盤管理

      div(style="margin-bottom: 20px;")
        div(style="color: red" class="mb-3") 1.未購買八字軟體，恕不提供八字命盤查閱及解析
        div(style="color: red") 2.為維護您的權益，命盤管理所儲存之八字命盤，不代為備存管理，請使用者自行備份存檔

      div(class="row no-gutters mx-n1 align-items-center" v-if="listModel.data")
        div(class="col-auto px-1")
          button(class="btn btn-outline-danger px-4" :disabled="!todolist.length || submitDisabled" @click="deleteTodolist") 刪除
        div(class="col-auto px-1")
          div(class="form-check mx-3")
            input(type="checkbox" class="form-check-input" :id="uuid.all" v-model="all" :value="true" @change="changeAll" :disabled="!listModel.data.length")
            label(class="form-check-label text-left fs-16" :for="uuid.all")
              span(class="form-check-box")
              span 全選
      div(v-if="animationGroup.length && listModel.data.length")
        div(class='border rounded border-success px-0 my-2' v-for="(modelItem, index) in listModel.data" :key="modelItem.id")
          button(class="btn-panel py-2 position-relative px-4" :class="{active: animationGroup[index].active}"  type="button" @click="toggleSlide($event, index)")
            div(class="form-check mx-3" style="pointer-events: auto;"  @click.stop)
              input(type="checkbox" class="form-check-input" :id="animationGroup[index].uuid" v-model="todolist" :value="modelItem.id" @change="changeTodo")
              label(class="form-check-label text-left fs-16" :for="animationGroup[index].uuid")
                span(class="form-check-box")
                span {{ modelItem.fortune_name }} 八字資料
            Icon( class="text-dark arrow" pattern="ArrowRight" size="24")
          div(class='px-2' ref="title")
            div(v-if="modelItem.mode === 'edit'")
              div(class="row flex-column flex-sm-row py-2")
                NatalchartEditor(:model="model" @submit="updateNatalchart(index)")
                div(class="col-auto col-md-4 d-flex align-items-end justify-content-end pt-3")
                  div(class="px-1")
                    button(class="btn btn-success px-4" @click="updateNatalchart(index)" :disabled="submitDisabled") 確定
                  div(class="px-1")
                    button(class="btn btn-secondary px-4 text-white" @click="cancelNatalchart(index)") 取消
            div(v-else class='border-top py-2 px-3')
              div(class="row mx-n2")
                div(class="col px-2")
                  div(class="d-flex py-1")
                    div(class="flex-shrink-0 my-1 mr-4" style="width: 64px;") 使用者
                    div(class="flex-grow-1 my-1") {{ modelItem.fortune_name }}
                    button(class="btn btn-icon flex-shrink-0 text-success my-1" @click="editNatalchart(index)" :disabled="editMode")
                      Icon(class="" pattern="Edit" size="24")
                  div(class="d-flex py-2")
                    div(class="flex-shrink-0 mr-4" style="width: 64px;") 性別
                    div(class="" v-if="modelItem.fortune_gender === 'M'") 男
                    div(class="" v-if="modelItem.fortune_gender === 'F'") 女
                  //- div(class="d-flex py-2")
                  //-   div(class="flex-shrink-0 mr-4" style="width: 64px;") 曆別
                  //-   div(class="") {{ modelItem.fortune_calendar }}
                  div(class="d-flex py-2")
                    div(class="flex-shrink-0 mr-4" style="width: 64px;") 出生日期(國曆)
                    div(class="") {{ dayFormatLuna(modelItem.result.CC, 'CC',modelItem) }}
                  div(class="d-flex py-2")
                    div(class="flex-shrink-0 mr-4" style="width: 64px;") 出生日期(農曆)
                    div(class="") {{ dayFormatLuna(modelItem.result.LC, 'LC',modelItem) }}
                  div(class="d-flex py-2")
                    div(class="flex-shrink-0 mr-4" style="width: 64px;") 出生日期(西元)
                    div(class="") {{ dayFormatLuna(modelItem.result.AD, 'AD',modelItem) }}
                  div(class="d-flex py-2")
                    div(class="flex-shrink-0 mr-4" style="width: 64px;") 出生時辰
                    div(v-if="modelItem.lucky")
                      span 吉時
                    div(v-else)
                      div(class="") {{ modelItem.fortune_hour.toString().padStart(2, '0') + ':' + modelItem.fortune_minute.toString().padStart(2, '0') }} {{ covChinaTime(modelItem.fortune_hour) }}
              div(class="row mx-n2" v-if="UserData.character_auth!='N'")
                section(class="col-12 col-lg-6 px-2 my-1")
                  div(class="border")
                    div(class="bg-success text-white px-3 py-1") 八字十神
                    div(class="p-1 font-size-control")
                        div(class="d-flex border-primary-style border-width-y font-weight-bold")
                          div(v-for="(data, i) in modelItem.result.name" class="col-3 px-0 d-flex flex-column align-items-center py-1" :class="{'border-primary-style border-width-l': i}")
                            span(:style="{color: i === 1 ? '#A30000' : ''}") {{ data[0] }}
                            span(:style="{color: i === 1 ? '#A30000' : ''}") {{ data[1] }}
                        div(class="d-flex border-primary-style border-width-b font-weight-bold")
                          div(v-for="(data, i) in modelItem.result.gz" class="col-3 px-0 d-flex flex-column align-items-center py-1" :class="{'border-primary-style border-width-l': i}")
                            span(v-if="data[0] !== '吉'") {{ data[0] }}
                            span(v-if="data[0] !== '吉'") {{ data[1] }}
                        div(class="d-flex border-primary-style border-width-b")
                          //- 寫死當吉時的時候，固定隱藏第一個
                          div(v-for="i in 4" class="col-3 py-1 px-0 d-flex justify-content-center overflow-auto" :class="{'border-primary-style border-width-l': i - 1}" :style="modelItem.fortune_hour !== '吉' || i !== 1 ? '' : 'visibility: hidden'")
                            div(v-for="j in 3" class="col-auto px-0" v-if="modelItem.result.tengod[(i - 1) * 3 + (j - 1)] && modelItem.result.tengod[(i - 1) * 3 + (j - 1)].title && modelItem.result.tengod[(i - 1) * 3 + (j - 1)].content")
                              div(style="border: 1px solid transparent;")
                                div(class="bg-success text-white p-1") {{ modelItem.result.tengod[(i - 1) * 3 + (j - 1)].title }}
                                div(class="p-1 d-flex flex-column align-items-center" style="background-color: rgba(86, 135, 15, 0.1);")
                                  span {{ modelItem.result.tengod[(i - 1) * 3 + (j - 1)].content[0] }}
                                  span {{ modelItem.result.tengod[(i - 1) * 3 + (j - 1)].content[1] }}
                section(class="col-12 col-lg-6 px-2 my-1")
                  div(class="border d-flex flex-column h-100")
                    div(class="bg-success flex-shrink-0 text-white px-3 py-1") 大運
                    div(class="flex-grow-1 font-size-control" style="min-height: 120px")
                      div(class="overflow-auto h-100 d-flex justify-content-center justify-content-sm-between p-1 text-center")
                        div(v-for="data in modelItem.result.bigfate" class="col px-0 d-flex flex-column" style="border: 1px solid transparent;")
                          div(class="d-flex flex-column flex-grow-1")
                            div(class="bg-success text-white p-1 flex-shrink-0") {{ data.title }}
                            div(class="p-1 d-flex flex-column flex-grow-1 align-items-center" style="background-color: rgba(86, 135, 15, 0.1);")
                              span {{ data.content[0] }}
                              span {{ data.content[1] }}
                section(class="col-12 col-lg-6 px-2 my-1")
                  div(class="border")
                    div(class="bg-success text-white px-3 py-1") 九宮
                    div(class="p-1")
                      div(class="mx-auto" style="max-width: 320px;")
                        svg(xmlns="http://www.w3.org/2000/svg" :viewBox="qimenBaguazhen.attrs.viewBox")
                          g(v-html="qimenBaguazhen.path" color="#009140")
                          text(v-for="(path, i) in qimenBaguazhen.textPath" v-bind="path.attrs" :title="path.text") {{ modelItem.result.nine[i] }}
                          text(v-for="(path, i) in qimenBaguazhen.taichiPath" v-bind="path.attrs" color="#009140" :title="path.text") {{ modelItem.result.personal[i] }}
                section(class="col-12 col-lg-6 px-2 my-1")
                  div(class="border")
                    div(class="bg-success text-white px-3 py-1") 東震食品
                    div(class="p-1")
                      div(class="mx-auto" style="max-width: 360px;" v-if="modelItem.result.five.length")
                        svg(xmlns="http://www.w3.org/2000/svg" width="100%" :viewBox="tocinFood.attrs.viewBox")
                          g(v-html="tocinFood.path")
                          g(title="中心" color="#009140")
                            path(v-if="modelItem.result.level === '+'" fill="currentColor" title="+" d="M181.5,148.1l0,6.9l6.9,0v5.6l-6.9,0l0,6.9h-5.6l0-6.9l-6.9,0V155l6.9,0l0-6.9H181.5z")
                            rect(v-if="modelItem.result.level === '-'" fill="currentColor" title="-" x="168.9" y="155" width="19.4" height="5.6")
                          g(v-for="(path, i) in tocinFood.morningPath" :title="path.title")
                            g(v-html="path.path" :color="getFiveElementColor(modelItem.result.five[i].morning)")
                            text(v-bind="path.attrs" color="#fff") {{ modelItem.result.five[i].morning }}
                          g(v-for="(path, i) in tocinFood.nightPath" :title="path.title")
                            g(v-html="path.path" :color="getFiveElementColor(modelItem.result.five[i].night)")
                            text(v-bind="path.attrs" color="#fff") {{ modelItem.result.five[i].night }}
      div(v-if="!editMode")
        div(class='border rounded border-success px-0 my-2')
          div(class="btn-panel py-2 position-relative px-4") 新增八字資料
          div(class='px-2')
            div(class='border-top py-2 px-3')
              div(class="row flex-column flex-sm-row")
                NatalchartEditor(:model="model" @submit="addNatalchart")
                div(class="col-auto col-md-4 d-flex align-items-end justify-content-end pt-3")
                  button(class="btn btn-success px-4" @click="addNatalchart" :disabled="submitDisabled") 新增
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapMutations, mapActions, mapState } from 'vuex'
import { createNatalcharts, updatetNatalcharts, deleteNatalchartsGroup } from '../../../api'
import { NatalchartModel } from '../../../models'
import NatalchartEditor from '../../../container/natalchart/Index.vue'
import qimenBaguazhen from '../../../components/icon/pattern/qimen-baguazhen'
import tocinFood from '../../../components/icon/pattern/tocin-food'

export default {
  name: 'Natalchart',
  components: {
    NatalchartEditor
  },
  data () {
    return {
      model: new NatalchartModel(),
      uuid: {
        all: uuidv4()
      },
      all: [],
      todolist: [],
      sortCache: [],
      animationGroup: [],
      qimenBaguazhen,
      tocinFood,
      submitDisabled: false
    }
  },
  created () {
    if (!this.UserData.loginStatus) {
      this.$store.commit('setLoginPage', this.$route.path)
      this.$router.replace('/member/login')
    }
  },
  mounted () {
    if (!this.UserData.loginStatus) {
      return
    }
    this.$store.dispatch('getListModel', {
      name: 'Natalcharts'
    }).then(() => {
      this.setGroup(this.listModel)
    })
  },
  computed: {
    ...mapState({
      global: state => state.global,
      UserData: state => state.model.UserData,
      listModel: state => state.model.Natalcharts
    }),
    editMode () {
      return this.listModel.data.some(p => p.mode === 'edit')
    }
  },
  methods: {
    ...mapMutations(['setAnimationGroup']),
    ...mapActions(['natalchartFormat']),
    getFiveElementColor (data) {
      switch (String(data)) {
        case '105': return '#2B415F'
        case '205': return '#68272B'
        case '305': return '#424A36'
        case '405': return '#9FA0A0'
        case '505': return '#9F7A4E'
        default: return '#fff'
      }
    },
    setGroup (model, submit) {
      return new Promise(resolve => {
        // 設定預設值給 Store 內儲存判斷
        this.animationGroup = model.data.map(item => {
          return {
            id: item.id,
            uuid: uuidv4(),
            active: false
          }
        })
        this.setAnimationGroup(this.animationGroup)
        this.$nextTick(() => {
          requestAnimationFrame(() => {
            const doms = this.$refs.title
            this.animationGroup.forEach((item, index) => {
              item.element = doms[index]
            })
            resolve(this.animationGroup)
            this.global.animationGroup.forEach((el, index) => {
              if (submit) {
                return
              }
              this.$store.dispatch('toggleAnimationTarget', {
                index,
                element: doms[index]
              })
            })
          })
        })
      })
    },
    toggleSlide (e, index) {
      // console.log('toggleSlide', index)
      // console.log('sliding', this.sliding)
      // console.log('this.$refs.title[index]', this.$refs.title[index])
      if (this.sliding) {
        return
      }
      this.sliding = true
      this.$store.dispatch('toggleAnimationTarget', {
        index,
        animation: {
          type: 'slide'
        },
        element: this.$refs.title[index],
        callback: () => {
          this.sliding = false
          // console.log('callback', index)
        }
      })
    },
    async addNatalchart () {
      if (this.submitDisabled) {
        return
      }
      const errorMessage = this.model.validate()
      if (errorMessage.length) {
        return
      }
      this.submitDisabled = true
      this.natalchartFormat(this.model).then(result => {
        if (result.errorstatus) {
          this.model.validate({
            error_message: { inclusion: { message: `^${result.error}` } }
          })
          this.submitDisabled = false
          return
        }

        if (result && result.leap) {
          this.model.fortune_is_leap = result.leap
        }
        this.model.result = result
        createNatalcharts(
          this.model.toDatabase()
        ).then(async res => {
          this.model.id = res.data.id
          console.log('createNatalcharts', res.data)
          this.listModel.data.push(new NatalchartModel(res.data))
          this.model = new NatalchartModel()
          this.submitDisabled = false
          const group = await this.setGroup(this.listModel, false)
          const index = group.length - 1
          this.$store.dispatch('toggleAnimationTarget', {
            index,
            animation: {
              type: 'slide'
            },
            element: this.$refs.title[index],
            callback: () => {
              this.sliding = false
              // console.log('callback', index)
            }
          })
        }).catch(() => {
          this.submitDisabled = false
        })
      }).catch(() => {
        this.submitDisabled = false
      })
    },
    changeAll () {
      if (this.all.length) {
        this.todolist = this.listModel.data.map(p => p.id)
      } else {
        this.todolist = []
      }
    },
    changeTodo () {
      if (this.listModel.data.length === this.todolist.length) {
        this.all.push(true)
      } else {
        this.all = []
      }
    },
    deleteTodolist () {
      this.submitDisabled = true
      // this.sortCache = this.listModel.data.map(p => p.id)
      deleteNatalchartsGroup({
        _method: 'delete',
        id: this.todolist
      }).then(() => {
        this.submitDisabled = false
        this.todolist.forEach(item => {
          const indexOf = this.listModel.data.findIndex(model => Number(model.id) === Number(item))
          console.log('indexOf', indexOf)
          this.listModel.data.splice(indexOf, 1)
        })
        // this.todolist.forEach(item => {
        //   const index = this.listModel.data.findIndex(model => Number(model.id) === Number(item))
        //   this.listModel.data.splice(index, 1)
        //   this.cancelGeneral(index)
        // })
        console.log(this.todolist)
        this.all = []
        this.todolist = []
        this.model = new NatalchartModel()
      }).catch(() => {
        this.submitDisabled = false
      })
    },
    editNatalchart (index) {
      localStorage.removeItem('deleteCache')
      this.sortCache = []
      this.listModel.data[index].mode = 'edit'
      this.model = new NatalchartModel(this.listModel.data[index])
    },
    async updateNatalchart (index) {
      console.log(index)
      if (this.submitDisabled) {
        console.log('return')
        return
      }
      const errorMessage = this.model.validate()
      if (errorMessage.length) {
        console.log('return')
        return
      }
      this.submitDisabled = TextTrackCueList
      this.natalchartFormat(this.model).then(result => {
        if (result.errorstatus) {
          this.model.validate({
            error_message: { inclusion: { message: `^${result.error}` } }
          })
          this.submitDisabled = false
          return
        }
        this.model.result = result
        updatetNatalcharts(
          this.model.id,
          this.model.toDatabase()
        ).then(async () => {
          this.listModel.data.forEach(p => {
            if (p.mode === 'edit') {
              p.mode = 'static'
            }
          })
          const targetModel = this.listModel.data.find(p => {
            return Number(p.id) === Number(this.model.id)
          })
          targetModel.set(this.model)
          this.model = new NatalchartModel()
          this.submitDisabled = false
        }).catch(() => {
          this.submitDisabled = false
        })
      }).catch(() => {
        this.submitDisabled = false
      })
    },
    cancelNatalchart (index) {
      this.listModel.data[index].mode = 'static'
      this.model = new NatalchartModel()
    }
  }
}
</script>
<style lang="scss">
  .border-primary-style {
    border-style: solid;
    border-color: rgba(86, 135, 15, 0.4);
  }
  .border-width-x{
    border-width: 0 1px;
  }
  .border-width-y{
    border-width: 1px 0;
  }
  .border-width-t{
    border-width: 1px 0 0 0;
  }
  .border-width-r{
    border-width: 0 1px 0 0;
  }
  .border-width-b{
    border-width: 0 0 1px 0;
  }
  .border-width-l{
    border-width: 0 0 0 1px;
  }
  .font-size-control{
    font-size: 12px;
    line-height: 1.2;
    @media (min-width: 450px) {
      font-size: 14px;
    }
    @media (min-width: 576px) {
      font-size: 16px;
    }
    @media (min-width: 768px) {
      line-height: 1.5;
    }
  }
</style>
